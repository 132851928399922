<template>
  <div class="home black-background">
    <section>
      <v-parallax src="/media/images/home-image-top.jpg" height="600">
        <v-layout column align-center justify-center class="white--text">
          <img
            src="/media/images/flashvision-complete.png"
            alt="FLASH VISION"
            height="200"
          />
          <v-btn
            class="mt-12"
            color="primary"
            hidden
            dark
            large
            @click="openBoard"
          >
            Open Dashboard DEMO
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="headline">It's storytelling with a purpose.</h2>
            <span class="subheading">
              Data visualization is another form of visual art that grabs our
              interest and keeps our eyes on the message.
            </span>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <font-awesome-icon
                      class="color-dv"
                      icon="hourglass-start"
                      size="2x"
                    />
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">
                      Real-time visualizations
                    </div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Real-time visualization of your KPIs everywhere you go. Stay
                    on top of your successes and setbacks by visualizing your
                    most important KPIs across multiple systems and departments
                    on real-time dashboards.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <font-awesome-icon
                      class="color-dv"
                      icon="chart-bar"
                      size="2x"
                    />
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Data Driven Culture</div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Make data insights accessible to the whole organization by
                    combining and sharing data from multiple business systems
                    onto a single dashboard. Start encouraging a data-driven
                    culture today by visualizing your data on dashboards.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <font-awesome-icon
                      class="color-dv"
                      icon="tachometer-alt"
                      size="2x"
                    />
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Data is prioritized</div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    It simplifies the visual representation of complex data and
                    helps stakeholders understand, analyze, and present key
                    insights. The core objective of a dashboard is to make
                    complex information accessible and easy to digest.
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section>
      <v-parallax src="/media/images/home-image-bottom.jpg" height="240">
        <v-layout column align-center justify-center>
          <v-btn
            class="mt-12 dark-text"
            color="primary"
            dark
            large
            @click="openBoard"
          >
            Discover More
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="headline">
              A case study: Real-time sensors data from <br />
              an air quality monitoring system for indoor environments
            </h2>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md6>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <font-awesome-icon
                      class="color-dv"
                      icon="chart-line"
                      size="2x"
                    />
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">Our Demo</div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    For our demo, Flash Vision is communicating via REST API
                    with sensors installed at
                    <a href="https://www.pointbergamo.com/" target="_blank"
                      >POINT</a
                    >, our operative headquarter in Bergamo (Italy). Flash
                    vision, hosted on our Aruba cluster, is receiving data from
                    the sensor every 60 seconds. Data is streamed real-time
                    using websockets to the browser. Moreover, a distributed
                    database for statistical analysys and time queries is
                    installed allowing user to explore data.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md6>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <font-awesome-icon class="color-dv" icon="fan" size="2x" />
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">
                      Indoor Air Quality Data
                    </div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    The sensor is monitoring temperature [°C], relative humidity
                    [%], Volatile Organic Compounds (VoC), such as concentration
                    of organic chemicals within air (dimensionless value between
                    0 and 5, where 0 stands for best and 5 stands for worst),
                    and Carbone Dioxide concentration (co2) with a value between
                    400 and 5000 [ppm].
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section>
      <v-parallax src="/media/images/home-image-bottom.jpg" height="100">
      </v-parallax>
    </section>

    <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-12">
          <v-flex xs12 sm4>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">D/Vision Lab</div>
              </v-card-title>
              <v-card-text class="text-justify">
                We are an innovative start-up focused on data visualization.
                With over 15 years of experience accumulated by its founders,
                D/Vision Lab operates in scientific, medical, engineering and
                services domains allowing visual exploration of data through
                advanced visualization technologies and artificial intelligence
                to enable fast, intuitive and deep understanding of the business
                processes.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn icon>
                  <a href="http://www.dvisionlab.com" target="blank">
                    <v-icon class="color-dv">mdi-web</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="mailto:info@dvisionlab.com" target="blank">
                    <v-icon class="color-dv">mdi-mail</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="http://www.github.com/dvisionlab" target="blank">
                    <v-icon class="color-dv">mdi-git</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="http://www.twitter.com/dvisionlab" target="blank">
                    <v-icon class="color-dv">mdi-twitter</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a
                    href="http://www.linkedin.com/company/dvisionlab"
                    target="blank"
                  >
                    <v-icon class="color-dv">mdi-linkedin</v-icon>
                  </a>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12 sm4 offset-sm1>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Contact Us</div>
              </v-card-title>
              <a href="http://www.dvisionlab.com" target="blank">
                <v-img
                  class="white--text align-end"
                  contain
                  src="/media/images/dvision_logo_2019_large.png"
                ></v-img>
              </a>
              <v-card-text class="text-justify"> </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted: function () {},
  methods: {
    openBoard() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>

<style scoped>
.dark-text {
  color: #000000 !important;
}
.v-application a {
  text-decoration: none;
}
.black-background {
  background-color: black;
}
.p0 {
  padding: 0px !important;
}
</style>
